import React, { useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { AppState, ArcadeInfo, ReduxProps, Play, Translate, Session } from '@nackle/arcade-core';
import { connect } from 'react-redux';
import { FALLBACK_ARCADE_LINK_BACKGROUND, FALLBACK_ARCADE_LINK_FOREGROUND } from '../../assets';
import { WidgetText } from '../../translation-data';
import { cssUrl } from '../../utils/escaping';
import { getEnvironment } from '../../utils/buildUrls';
import '../common/flex.less';
import './style.less';

export interface Props {
    arcade: ArcadeInfo;
    arcadeUrl: string;
    index: number;

    /**
     * Optional URL inside of ID service to perform external authentication before navigating to Nackle-based sites
     * like the the Arcade Floor. Required for sites that don't use Bridge for authentication, e.g. Daymaker < 6.6(?)
     */
    ssoEndpoint?: string;
}

const mapStateToProps = (state: AppState, props: Props) => {
    const tokensRemaining = Play.countAvailableNonInstantPlayTokens(state);
    const arcade = state.games.arcadeList.isLoaded && state.games.arcadeList.data[0];
    const sessionPayload:any = jwt_decode(`${Session.getSessionId(state)}`);
    const environment:any = getEnvironment(sessionPayload.iss);

    return {
        backgroundImageUrl: arcade && arcade.arcadeLinkBackgroundImageUrl || FALLBACK_ARCADE_LINK_BACKGROUND(environment),
        foregroundImageUrl: arcade && arcade.arcadeLinkForegroundImageUrl || FALLBACK_ARCADE_LINK_FOREGROUND(environment),
        imageUrl: undefined,
        tokensRemaining,
    };
};

function ArcadeView(
    {
        arcadeUrl,
        backgroundImageUrl,
        foregroundImageUrl,
        index,
        ssoEndpoint,
        tokensRemaining,
    }: ReduxProps<Props, typeof mapStateToProps>,
): JSX.Element {
    const playButtonText = <Translate id={WidgetText.PLAY_IN_ARCADE} />;

    const tokenCounter = (tokensRemaining.isLoaded) ?
        (
            <>
                <div className="creditsLabel"><Translate id={WidgetText.CREDITS_LABEL} /></div>
                <div className="tokensRemaining" id={`arcade_${index}_tokens_remaining`}>
                    {tokensRemaining.data}
                </div>
            </>
        ) : null;

    const renderButtonWithTranslation = useCallback((playButtonTooltip: string): JSX.Element => {
        const detailsOverlay = (
            <div className="detailsOverlay">
                <div className="arcadeLinkIcon" style={{ backgroundImage: `url("${foregroundImageUrl}")` }} />
                <span className="playButtonLabel">{playButtonText}</span>
            </div>
        );

        // If we've been told to use an SSO endpoint, we can't just link to the Arcade Floor because the session
        // cookie won't be set. Instead, we have to send the browser to the ID service, which will set the auth
        // cookies we need before sending us to the Arcade Floor. This has to be done with an old-fashioned form POST.
        // https://gitlab.biw-services.com/nackle/engage/experience/arcade-ui/issues/96#note_495024
        if (ssoEndpoint) {
            return (
                <form
                    action={ssoEndpoint}
                    className="playButtonForm"
                    id={`arcade_${index}_form`}
                    method="POST"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <input
                        type="hidden"
                        name="location"
                        value={arcadeUrl}
                    />

                    <button
                        className="arcadeLink"
                        id={`arcade_${index}_link`}
                        style={{ backgroundImage: cssUrl(backgroundImageUrl) }}
                        type="submit"
                        title={playButtonTooltip}
                    >
                        {detailsOverlay}
                    </button>
                </form>
            );
        }

        return (
            <a
                className="arcadeLink"
                id={`arcade_${index}_link`}
                href={arcadeUrl}
                style={{ backgroundImage: cssUrl(backgroundImageUrl) }}
                target="_blank"
                rel="noopener noreferrer"
                title={playButtonTooltip}
            >
                {detailsOverlay}
            </a>
        );
    }, [ssoEndpoint]);

    return (
        <>
            <div className="header">
                <div className="arcadeName"><Translate id={WidgetText.ARCADE_HEADER} /></div>
                <div className="flexSpacer" />
                {tokenCounter}
            </div>

            <Translate id={WidgetText.PLAY_IN_ARCADE}>{renderButtonWithTranslation}</Translate>
        </>
    );
}

export default connect(mapStateToProps)(ArcadeView);
