/**
 * A collection of translation keys related to the arcade floor not from any particular
 * component.
 */
export enum FloorText {
    PLAY_NOW_BUTTON_LABEL = 'floor.PLAY_NOW_BUTTON_LABEL',
    INSTANT_PLAYS_HEADER = 'floor.INSTANT_PLAYS_HEADER',
    CREDITS_LABEL = 'floor.CREDITS_LABEL',
    INSTANT_PLAY_GAME_LABEL = 'floor.INSTANT_PLAY_GAME_LABEL',
    ROOM_GAME_LABEL = 'floor.ROOM_GAME_LABEL',
    PLAY_COUNT_LABEL = 'floor.PLAY_COUNT_LABEL',
}

/**
 * A collection of translation keys related to the Game Banner component displayed at the
 * top of the arcade Floor (specifically `GameBanner.ts`);
 */
export enum GameBannerText {
    FALLBACK_PROMOTION_TITLE = 'floor.FALLBACK_PROMOTION_TITLE',
    FALLBACK_PROMOTION_DESCRIPTION = 'floor.FALLBACK_PROMOTION_DESCRIPTION',
}

/**
 * A collection of translation keys related to the arcade widget not form any particular
 * component.
 */
export enum WidgetText {
    ARCADE_HEADER = 'widget.ARCADE_HEADER',
    CREDITS_LABEL = 'widget.CREDITS_LABEL',
    INSTANT_PLAYS_HEADER = 'widget.INSTANT_PLAYS_HEADER',
    PLAY_IN_ARCADE = 'widget.PLAY_IN_ARCADE',
    PLAY_BUTTON_COUNT_LABEL = 'widget.PLAY_BUTTON_COUNT_LABEL',
    PLAY_NOW_WITH_GAME = 'widget.PLAY_NOW_WITH_GAME',
    NO_INSTANT_PLAYS = 'widget.NO_INSTANT_PLAYS',
}

/**
 * A collection of translation keys related to the Auto-Play switch component (specifically
 * `AutoPlayController.ts`).
 */
export enum AutoPlaySwitchText {
    LABEL = 'core.autoPlay.LABEL',
    INFO_HEADER = 'core.autoPlay.INFO_HEADER',
    INFO_BODY = 'core.autoPlay.INFO_BODY',
    INFO_ENABLED_LABEL = 'core.autoPlay.INFO_IS_ENABLED_LABEL',
    INFO_DISABLED_LABEL = 'core.autoPlay.INFO_IS_DISABLED_LABEL',
    ENABLE_HEADER = 'core.autoPlay.ENABLE_HEADER',
    ENABLE_BODY = 'core.autoPlay.ENABLE_BODY',
    ENABLE_CONSEQUENCE = 'core.autoPlay.ENABLE_CONSEQUENCE',
    ENABLE_CANCEL = 'core.autoPlay.ENABLE_CANCEL',
    ENABLE_CONFIRM = 'core.autoPlay.ENABLE_CONFIRM',
    DISABLE_HEADER = 'core.autoPlay.DISABLE_HEADER',
    DISABLE_BODY = 'core.autoPlay.DISABLE_BODY',
    DISABLE_CANCEL = 'core.autoPlay.DISABLE_CANCEL',
    DISABLE_CONFIRM = 'core.autoPlay.DISABLE_CONFIRM',
}
