// Polyfill promises for IE 11 support (import before everything else!)
import 'regenerator-runtime';
import 'ts-polyfill';
import 'whatwg-fetch';

import jwt_decode from 'jwt-decode';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { GAME_LOADER_CSS_PATH, NACKLE_PAPER_CSS_PATH } from './assets';
import { loadStylesheet } from './utils/loadStylesheet';
import { ArcadeWidget, Props } from './widget';
import { getEnvironment } from './utils/buildUrls';

export interface WidgetOptions extends Props {
    /**
     * The element to render the widget into
     */
    container?: HTMLElement | null;

    /**
     * Use the host page's Nackle Paper rather than downloading the widget's own
     * special build of it.
     */
    useHostPageNacklePaper?: boolean;
}

export function validateOptions(options: unknown) {
    if (typeof options !== 'object' || options === null) {
        throw new Error('renderArcadeWidget() must be called with an object');
    }

    const {
        container,
        locale,
        onStore,
        sessionId,
        ssoEndpoint,
        useHostPageNacklePaper = false,
    } = options as { [index: string]: unknown };

    if (!(container instanceof HTMLElement)) {
        throw new Error('Must provide property "container" into which the component will render');
    }

    if (!locale || typeof locale !== 'string') {
        throw new Error('Must provide property "locale" containing an IETF language tag (such as "en-US")');
    }

    if (!sessionId || typeof sessionId !== 'string') {
        throw new Error('Must provide property "sessionId" containing JWT session token string');
    }

    const decodedSessionId = jwt_decode<{ [key: string]: any }>(sessionId);

    if (!(decodedSessionId.person_id || decodedSessionId.external_id)) {
        throw new Error('sessionId must define either `person_id` or `external_id`');
    }

    const environment = getEnvironment(decodedSessionId.iss);

    window.arcadeEnvironment = environment ? `${environment}` : 'prod';

    if (ssoEndpoint !== undefined && (typeof ssoEndpoint !== 'string' || ssoEndpoint === '')) {
        throw new Error('ssoEndpoint must be a valid URL');
    }

    if (onStore !== undefined && (typeof onStore !== 'function')) {
        throw new Error('onStore must be a function');
    }

    return {
        container,
        props: {
            locale,
            onStore: onStore as WidgetOptions['onStore'],
            sessionId,
            environment: environment as string,
            ssoEndpoint: ssoEndpoint as string | undefined,
        },
        useHostPageNacklePaper: Boolean(useHostPageNacklePaper),
    };
}

export async function renderArcadeWidget(options: Partial<WidgetOptions> = {}): Promise<{ destroy(): void }> {
    const { container, props, useHostPageNacklePaper } = validateOptions(options);
    const gameLoaderCssPath = GAME_LOADER_CSS_PATH(props.environment);
    const nacklePaperCssPath = NACKLE_PAPER_CSS_PATH(props.environment);
    const gameLoaderCssPromise = loadStylesheet(gameLoaderCssPath);
    const nacklePaperCssPromise = useHostPageNacklePaper ? undefined : loadStylesheet(nacklePaperCssPath);

    // Show nothing until styles are loaded to prevent showing unstyled content
    await Promise.all<any>([gameLoaderCssPromise, nacklePaperCssPromise]);

    const root = createRoot( container );
    root.render( <ArcadeWidget {...props} /> );

    return {
        destroy: () => {
            // Unmount the widget
            root.unmount();

            // Unmount the Nackle Paper stylesheet, too.
            if (nacklePaperCssPromise) {
                nacklePaperCssPromise.then((linkElement) => {
                    if (linkElement.parentElement) {
                        linkElement.parentElement.removeChild(linkElement);
                    }
                });
            }
        },
    };
}

declare global {
    interface Window {
        renderArcadeWidget: (options: WidgetOptions) => void;
        arcadeEnvironment: string;
    }
}

window.renderArcadeWidget = renderArcadeWidget;
