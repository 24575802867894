import { AppState, Games, Links, ReduxProps } from '@nackle/arcade-core';
import React from 'react';
import { connect } from 'react-redux';
import ArcadeView from './ArcadeView';
import { arcadeLinkError, arcadeLinkLoading } from './style.less';

export function Loading() {
    return <div className={arcadeLinkLoading}>Loading...</div>;
}

export function Error() {
    return <div className={arcadeLinkError}>(!) Could not load Arcade</div>;
}

export interface Props {
    /**
     * Optional URL inside of ID service to perform external authentication before navigating to Nackle-based sites
     * like the the Arcade Floor. Required for sites that don't use Bridge for authentication, e.g. Daymaker < 6.6(?)
     */
    ssoEndpoint?: string;
}

const mapStateToProps = (state: AppState) => ({
    arcades: Games.getArcadeList(state),
    arcadeUrl: Links.getArcadeUrl(state),
    hideArcadeSection: Games.shouldHideArcadeFloorLink(state),
});

function ArcadeSection(
    {
        arcades,
        arcadeUrl,
        hideArcadeSection,
        ssoEndpoint,
    }: ReduxProps<Props, typeof mapStateToProps>,
) {

    if (arcades.error || arcadeUrl.error) {
        return <Error />;
    }

    if (!arcades.isLoaded || !arcadeUrl.isLoaded) {
        return <Loading />;
    }

    // Arcade floor was explicitly suppressed
    if (hideArcadeSection.isLoaded && hideArcadeSection.data === true) {
        return <></>;
    }

    // Arcade floor doesn't exist, so don't show any links.
    const url = arcadeUrl.data;
    if (!url) {
        return <></>;
    }

    const content = arcades.data.map((arcade, index) => (
        <ArcadeView
            arcade={arcade}
            arcadeUrl={url}
            index={index}
            key={arcade.id}
            ssoEndpoint={ssoEndpoint}
        />
    ));

    return <>{content}</>;
}

export default connect(mapStateToProps)(ArcadeSection);
