/** Size to render the SVG at **/
const SIZE = 60;

export const FALLBACK_IMAGE = `data:image/svg+xml,
<svg xmlns="http://www.w3.org/2000/svg" width="${SIZE}" height="${SIZE}">
    <rect x="0.5" y="0.5" width="${SIZE - 1}" height="${SIZE - 1}" style="fill: rgb(100,100,100); stroke: rgb(200,200,200);" />
    <line x1="0" y1="0" x2="${SIZE}" y2="${SIZE}" style="stroke: rgb(200,200,200);" />
    <line x1="0" y1="${SIZE}" x2="${SIZE}" y2="0" style="stroke: rgb(200,200,200);" />
</svg>
`.replace(/\n/g, '');
