/**
 * Build URLs and variables for resources
 */

export function getEnvironment(url:string) {
    if ( /localhost/.test( url ) ) {
        return 'qa';
    }

    return url.toLowerCase().replace('https://', '').split('.').find((chunk) => {
        return ['qa', 'pprd'].indexOf(chunk) >= 0;
    } );
}

function getUrl(url:string) {
    const env = getEnvironment(window.location.hostname);
    return url.replace('(env.)', env ? env + '.' : '')
}

export function getDeployedResourceUrl(environment:string = '') {
    // No environment = PROD
    if (!environment) {
        return 'https://arcade.biw.cloud';
    }

    return `https://${environment}.arcade.biw.cloud`;
}

function determineWidgetUrl(environment:string) {
    if (/localhost/.test(window.location.hostname)) {
        return 'http://localhost:4620';
    }

    return `${getDeployedResourceUrl(environment)}/widget`;
}

function determineLoaderUrl(environment:string) {
    if (/localhost/.test(window.location.hostname)) {
        return 'https://qa.arcade.biw.cloud/loader/index.html';
    }

    return `${getDeployedResourceUrl(environment)}/loader/index.html`;
}

export const getWidgetBaseUrl = (environment:string = '') => determineWidgetUrl(environment);
export const getLoaderBaseUrl = (environment:string = '') => determineLoaderUrl(environment);
export const idURL = getUrl('https://(env.)api.biw.cloud/v1/id');
