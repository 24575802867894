import { AppState, ReduxProps, Translate, Session } from '@nackle/arcade-core';
import React from 'react';
import { connect } from 'react-redux';
import { FALLBACK_INSTANT_PLAY_BACKGROUND, FALLBACK_INSTANT_PLAY_FOREGROUND } from '../../assets';
import { WidgetText } from '../../translation-data';
import { cssUrl } from '../../utils/escaping';
import './NoInstantPlayView.less';
import jwt_decode from 'jwt-decode';
import { getEnvironment } from '../../utils/buildUrls';

const mapStateToProps = () => {
    return (state: AppState) => {
        const arcade = state.games.arcadeList.isLoaded && state.games.arcadeList.data[0];
        const sessionPayload:any = jwt_decode(`${Session.getSessionId(state)}`);
        const environment:any = getEnvironment(sessionPayload.iss);

        return {
            backgroundImageUrl: arcade && arcade.noInstantPlayBackgroundImageUrl || FALLBACK_INSTANT_PLAY_BACKGROUND(environment),
            foregroundImageUrl: arcade && arcade.noInstantPlayForegroundImageUrl || FALLBACK_INSTANT_PLAY_FOREGROUND(environment),
        };
    };
};

const NoInstantPlayView = ({ backgroundImageUrl, foregroundImageUrl }: ReduxProps<{}, typeof mapStateToProps>) => {

    return (
        <Translate fallback="No Instant Plays available" id={WidgetText.NO_INSTANT_PLAYS}>{(label):any => {
            return (
                    <div
                        className="noInstantPlays"
                        id={ 'no_instant_plays_available' }
                        style={ { display: 'flex', backgroundImage: cssUrl(backgroundImageUrl) } }
                    >
                        <div className="noInstantPlaysInner">
                            <div className="noInstantPlaysIcon" style={ {  backgroundImage: `url("${ foregroundImageUrl }")` } }>
                            </div>
                            <span className="noInstantPlaysLabel">{ label }</span>
                        </div>
                    </div>
                )
            }
        }</Translate>
    )
};

 
export default connect(mapStateToProps)(NoInstantPlayView);
