import { CabinetEntryView, Games, Loadable, Token, Tokens } from '@nackle/arcade-core';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import { createSelector } from 'reselect';
import { IndividualGame } from '../view/instantPlay/InstantPlayView';
import { Status } from '../view/instantPlay';

export const buildInstantPlayGames = () => createSelector(
    [Games.getCabinetList, Games.getRoomList, Tokens.getAvailableTokens],
    (cabinetList, roomList, tokenList: Loadable<Token[]>): IndividualGame[] | Status => {
        if (cabinetList.isLoaded && roomList.isLoaded && tokenList.isLoaded) {

            const freePlayRooms = filter(roomList.data, { freePlay: true });

            return flatMap(freePlayRooms, (room) => {
                const roomTokens = filter(tokenList.data, (token) => token.roomId === room.id);

                // Look for cabinets in the room.
                //   In theory, there should be EXACTLY one cabinet in a free play room, but the data model allows
                // there to be zero or many such cabinets. In case this happens, we can still fail in a "safe" way
                // by returning zero or multiple "free plays" connected to the same room.
                return flatMap(room.cabinets, ({ cabinetId }: CabinetEntryView): IndividualGame[] => {
                    const cabinetView = find(cabinetList.data, { cabinetId });
                    if (!cabinetView) {
                        return [];
                    }

                    // If you don't have enough tokens to play, don't even show the game.
                    // Right now all games are hard-coded to cost 1 token, but we anticipate that this will change.
                    const requiredTokens = 1;
                    if (roomTokens.length < requiredTokens) {
                        return [];
                    }
                    const playCount = Math.floor(roomTokens.length / requiredTokens);

                    return [{
                        id: cabinetView.cabinetId,
                        nameFallback: cabinetView.name,
                        nameKey: Games.cmxKeys.formatCmxRomNameKey(cabinetView.romId),
                        imageUrl: cabinetView.image || cabinetView.bannerImage || undefined,
                        playCount,
                        romId: cabinetView.romId,
                        roomId: room.id,
                    }];
                });
            });
        }

        if (cabinetList.error || roomList.error || tokenList.error) {
            return Status.ERROR;
        }

        return Status.LOADING;
    },
);
