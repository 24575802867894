// This lives in its own module so it can be mocked-out.
// It turns out even though we've told Webpack to use `fileLoader` to load the file's path,
// Jest lacks a similar mechanism so it tries to actually import the loader package like
// any other module.

import { getLoaderBaseUrl, getWidgetBaseUrl } from '../utils/buildUrls';

import '@nackle/game-loader-component/static/loader.css';
export const GAME_LOADER_CSS_PATH = (environment:string) => `${getWidgetBaseUrl(environment)}/loader.css`;

import '@nackle/game-loader-component/static/widget-nackle-paper.css';
export const NACKLE_PAPER_CSS_PATH = (environment:string) => `${getWidgetBaseUrl(environment)}/widget-nackle-paper.css`;

import '@nackle/game-loader-component/static/award-swirl.png';

export const LOADER_URL = (environment:string) => getLoaderBaseUrl(environment);

export const IMG_AWARD_SWIRL = '/award-swirl.png';

try {
    // try to preload this to make the gameplay experience better
    new Image().src = IMG_AWARD_SWIRL;
// tslint:disable-next-line: no-empty
} catch {}

import './instantPlayBackground.png';
export const FALLBACK_INSTANT_PLAY_BACKGROUND = (environment:string) => `${getWidgetBaseUrl(environment)}/instantPlayBackground.png`;

import './instantPlayForeground.png';
export const FALLBACK_INSTANT_PLAY_FOREGROUND = (environment:string) => `${getWidgetBaseUrl(environment)}/instantPlayForeground.png`;

import './arcadeLinkBackground.png';
export const FALLBACK_ARCADE_LINK_BACKGROUND = (environment:string) => `${getWidgetBaseUrl(environment)}/arcadeLinkBackground.png`;

import './arcadeLinkForeground.png';
export const FALLBACK_ARCADE_LINK_FOREGROUND = (environment:string) => `${getWidgetBaseUrl(environment)}/arcadeLinkForeground.png`;
