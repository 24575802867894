import { AppState, AutoPlayController, Games, ReduxProps, Translate } from '@nackle/arcade-core';
import { dataOrElse } from '@nackle/arcade-core/src';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { buildInstantPlayGames } from '../../controller/instantPlayGames';
import { WidgetText } from '../../translation-data';
import InstantPlayView from './InstantPlayView';
import NoInstantPlayView from './NoInstantPlayView';

import {
    autoPlayToggleContainer,
    instantPlayError,
    instantPlayGameContainer,
    instantPlayHeader,
    instantPlayHeaderText,
    instantPlayLoading,
} from './style.less';

function Loading() {
    return <div className={instantPlayLoading}>Loading...</div>;
}

function Error() {
    return <div className={instantPlayError}>(!) Could not load Instant Play games</div>;
}

export enum Status {
    LOADING = 'LOADING',
    ERROR = 'ERROR',
}

const mapStateToProps = () => {
    const instantPlayGames = buildInstantPlayGames();
    return (state: AppState) => ({
        hideInstantPlays: Games.shouldHideInstantPlays(state),
        instantPlayGames: instantPlayGames(state),
    });
};

function InstantPlaySection({ hideInstantPlays, instantPlayGames }: ReduxProps<{}, typeof mapStateToProps>) {
    const noGames = !Array.isArray(instantPlayGames) || instantPlayGames.length === 0;
    if (dataOrElse(hideInstantPlays, false) && noGames) {
        return <></>;
    }

    let content: ReactNode;
    if (instantPlayGames === Status.LOADING) {
        content = <Loading />;
    } else if (instantPlayGames === Status.ERROR) {
        content = <Error />;
    } else if (instantPlayGames.length > 0) {
        content = (
            <div className={instantPlayGameContainer}>
                {instantPlayGames.map((game: any) =>
                    <InstantPlayView key={game.id} game={game} />,
                )}
            </div>
        );
    } else {
        content = <NoInstantPlayView />;
    }

    return (
        <>
            <div className="instantPlayHeader">
                <div className="instantPlayHeaderText">
                    <Translate id={WidgetText.INSTANT_PLAYS_HEADER} />
                </div>
                <AutoPlayController className={autoPlayToggleContainer} />
            </div>
            {content}
        </>
    );
}

export default connect(mapStateToProps)(InstantPlaySection);
