export function loadStylesheet(url: string): Promise<HTMLLinkElement> {
    const linkTag = document.createElement('link');
    linkTag.rel = 'stylesheet';
    linkTag.type = 'text/css';
    linkTag.href = url;
    document.head.appendChild(linkTag);

    return new Promise((resolve) => {
        linkTag.addEventListener('load', () => resolve(linkTag));

        // Even if there's an error, I'd rather show unstyled content than no content
        linkTag.addEventListener('error', (err) => {
            console.warn('Stylesheet', url, 'failed to load:', err);
            // resolve();
        });
    });
}
