export { FloorText, WidgetText, AutoPlaySwitchText, GameBannerText } from './translation-keys';
export { ProjectKeys } from './project-keys';

/**
 * At the moment, all of the translation keys, project keys (for CMX or Translation Service),
 * and language bundles for the arcade are gathered in this location and exported out so that
 * each package can grab the data that they need.
 *
 * There are some components that are shared across the arcade that rely on some set of
 * translation keys. A good example of this is the Auto-Play Switch. It is used in both the
 * arcade Floor and Widget and it uses a single set of translation keys. Because of this, it
 * doesn't make sense to keep the translation keys for that component in any single package.
 *
 * Also, this keeps all data related to translations (not necessarily the components that handle
 * the translation) in one place so that it's easy to find which is always nice.
 *
 * Each package has a `translations` folder in it's `src` folder importing data appropriate for
 * that package. The contents of that package can then just ask for translation keys from that
 * local location.
 */
