import { AppState, Play, ReduxProps, Translate } from '@nackle/arcade-core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WidgetText } from '../../translation-data';
import { cssUrl } from '../../utils/escaping';
import { FALLBACK_IMAGE } from '../fallbackImage';
import './style.less';

export interface IndividualGame {
    id: string;
    /**
     * Fallback name if the translation doesn't exist
     */
    nameFallback: string;
    /**
     * Translation key in CMX
     */
    nameKey: string;
    imageUrl: string | undefined;
    playCount: number;
    romId: string;
    roomId: string;
}

interface Props {
    game: IndividualGame;
}

const mapStateToProps = (state: AppState) => {
    return {};
};

const mapDispatchToProps = {
    openGame: Play.openGame,
};

class InstantPlayView extends Component<ReduxProps<Props, typeof mapStateToProps, typeof mapDispatchToProps>> {
    public render() {
        const { game } = this.props;

        const gameSlug = game.nameFallback.replace(/\W+/g, '_').toLocaleLowerCase();

        return (
            <Translate fallback={game.nameFallback} id={game.nameKey} stripHtml>{(gameName) =>
                <Translate id={WidgetText.PLAY_NOW_WITH_GAME} args={{ gameId: gameName }}>{(playButtonTooltip) =>
                    <button
                        {...{ 'data-rom-id': game.romId }}
                        className="instantPlayGame"
                        id={`instant_play_${gameSlug}_launch`}
                        onClick={this.handleClickPlay}
                        style={ { height: 160, alignItems: 'center', borderRadius: 10, minWidth: '-webkit-fill-available', backgroundImage: cssUrl(game.imageUrl || FALLBACK_IMAGE) } }
                        title={playButtonTooltip}
                    >
                        <div className="detailsOverlay">
                            <div style={ { fontVariant: 'all-small-caps', fontWeight: 'bold', fontSize: 'x-large', color: 'lightgray' } } className="gameTitle" id={`instant_play_${gameSlug}_title`}>
                                {gameName}
                            </div>
                        </div>

                        <div style={ { opacity: .85, backgroundColor: 'black', borderRadius: 999, float: 'right', fontWeight: 'bold', fontSize: 'medium', color: 'white' } } className="playLozenge" id={`instant_play_${gameSlug}_play_count`}>
                            <Translate parseHtml id={WidgetText.PLAY_BUTTON_COUNT_LABEL} args={{ playCount: game.playCount, playCountClass: 'playLozengeCount' } } />
                        </div>
                    </button>
                }</Translate>
            }</Translate>
        );
    }

    private handleClickPlay = () => {
        const { game, openGame } = this.props;
        openGame(game.id, game.roomId).catch((e) => { console.error(e); });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstantPlayView);
