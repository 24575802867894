import {
    AppState,
    GameLoaderController,
    Games,
    Links,
    Preferences,
    ReduxProps,
    Session,
    Tokens,
    TranslationLoader,
} from '@nackle/arcade-core/src';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ArcadeWidgetView } from '../view/ArcadeWidgetView';
import { IMG_AWARD_SWIRL, LOADER_URL } from '../assets';
import { ProjectKeys } from '../translation-data';
import ArcadeSection from '../view/arcadeLink';
import InstantPlaySection from '../view/instantPlay';

export interface Props {
    /**
     * Optional URL inside of ID service to perform external authentication before navigating to Nackle-based sites
     * like the the Arcade Floor. Required for sites that don't use Bridge for authentication, e.g. Daymaker < 6.6(?)
     */
    ssoEndpoint?: string;

    /**
     * The environment the widget is being used in
     */
    environment: string;
}

const mapStateToProps = (state: AppState) => ({
    locale: Session.getLocale(state),
});

const mapDispatchToProps = {
    loadArcadesAndTheirData: Games.loadArcadesAndTheirData,
    loadArcadeUrl: Links.loadArcadeUrl,
    loadPreferences: Preferences.loadPreferences,
    loadTokens: Tokens.loadTokens,
};

class ArcadeWidgetController extends Component<ReduxProps<Props, typeof mapStateToProps, typeof mapDispatchToProps>> {
    public componentDidMount() {
        this.props.loadArcadesAndTheirData();
        this.props.loadTokens();
        // this.props.environment comes from the ISS URL in the JWT token. The ISS URL is used to get the
        // environment for making the Arcade Floor URL when the Arcade Widget is being used in Daymaker.
        this.props.loadArcadeUrl(this.props.environment);
        this.props.loadPreferences();
    }

    loaderUrl = LOADER_URL(this.props.environment);

    public render() {
        return (
            <>
                <ArcadeWidgetView>
                    <InstantPlaySection />
                    <ArcadeSection ssoEndpoint={this.props.ssoEndpoint} />
                </ArcadeWidgetView>
                <GameLoaderController
                    imageUrls={{ IMG_AWARD_SWIRL }}
                    loaderUrl={this.loaderUrl}
                />
                <TranslationLoader projectKeys={[ProjectKeys.ARCADE_UI, Games.cmxKeys.ARCADE_DATA_PROJECT_KEY]} />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArcadeWidgetController);
