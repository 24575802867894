import { createAppStore, Session } from '@nackle/arcade-core';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import ArcadeWidgetController from './controller/ArcadeWidgetController';

export interface Props {
    locale?: string | any;

    /**
     * Hook to get a reference to the internal store
     */
    onStore?: (store: Store<any>) => any;

    /**
     * The roster compliant session for the user
     */
    sessionId: string;

    /**
     * The environment the widget is being used in
     */
    environment: string;

    /**
     * URL to use to complete the SSO sign-in process before . Only specify if the host page does NOT use
     */
    ssoEndpoint?: string;
}

interface State {
    store: Store<any>;
}

export class ArcadeWidget extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const store = createAppStore();
        this.state = { store };

        if (props.onStore) {
            try {
                props.onStore(store);
            } catch (e) {
                console.error(e);
            }
        }

        const dispatch = store.dispatch as any;
        dispatch(Session.setLocale(props.locale));
        dispatch(Session.loadTokenString(props.sessionId));
    }

    public render() {
        const { store } = this.state;
        return (
            <Provider store={store}>
                <ArcadeWidgetController
                    ssoEndpoint={this.props.ssoEndpoint}
                    environment={this.props.environment}
                />
            </Provider>
        );
    }
}
