import React, { ReactNode } from 'react';
import './style.less';

interface Props {
    children: ReactNode | ReactNode[];
}

export function ArcadeWidgetView( { children }: Props) {
    return (
        <div>
            {children}
        </div>
    );
}
